import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import BgScheduleInsideResp from "../../Images/BgScheduleInsideResp.jpg";
import ImgBgScheduleInside from "../../Images/BgScheduleInside.jpg";
import IconSchedule1 from "../../Images/IconSchedule1.png";
import IconSchedule2 from "../../Images/IconSchedule2.png";
import IconSchedule3 from "../../Images/IconSchedule3.png";
import IconSchedule4 from "../../Images/IconSchedule4.png";
import IconSchedule5 from "../../Images/IconSchedule5.png";
import IconSchedule6 from "../../Images/IconSchedule6.png";
import useData from "../../Utils/useData";

const Banner = () => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    useData()
      .getData("agenda.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  }, []);
  return (
    <div style={{zIndex:1}} id="teste">
      <S.OutBgResp>
        <img src={BgScheduleInsideResp} />
      </S.OutBgResp>

      <S.BgSchedule>
        <img src={ImgBgScheduleInside} />
      </S.BgSchedule>

      <S.OutSchedule>
        <S.TextProduction1>Agenda</S.TextProduction1>
        <S.ContentSchedule>
          <S.TopContentSchedule>
            <S.LeftContentSchedule>
              <S.OutImgIconSchedule>
                <img src={IconSchedule1} />
              </S.OutImgIconSchedule>
              <S.TextSchedule2>Data</S.TextSchedule2>
            </S.LeftContentSchedule>
            <S.CenterContentSchedule>
              <S.OutImgIconSchedule>
                <img src={IconSchedule2} />
              </S.OutImgIconSchedule>
              <S.TextSchedule2>Local</S.TextSchedule2>
            </S.CenterContentSchedule>
            <S.LeftContentSchedule className="LeftContentSchedule3">
              <S.OutImgIconSchedule>
                <img src={IconSchedule3} />
              </S.OutImgIconSchedule>
              <S.TextSchedule2>Ingressos</S.TextSchedule2>
            </S.LeftContentSchedule>
          </S.TopContentSchedule>

          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <S.OutItemsSchedule key={index}>
                <S.ItemSchedule>
                  <S.LeftItemSchedule>
                    <S.TextItemSchedule1 className="TextSchedule">{data.c_data.split("/")[0]}</S.TextItemSchedule1>
                    <S.OutTextItemSchedule2>
                      <S.TextItemSchedule2>{data.c_data_mes}</S.TextItemSchedule2>
                      <S.TextItemSchedule3>{data.c_data.split("/")[2]}</S.TextItemSchedule3>
                    </S.OutTextItemSchedule2>
                  </S.LeftItemSchedule>
                  <S.CenterItemSchedule>
                    {data.c_cidade === "#DANIELCORP" ? (
                      <S.TextItemSchedule4>{data.c_cidade}</S.TextItemSchedule4>
                    ) : (
                      <S.TextItemSchedule4>
                        {data.c_cidade} - {data.c_sigla}
                      </S.TextItemSchedule4>
                    )}
                    <S.TextItemScheule5>{data.c_local}</S.TextItemScheule5>
                    {data.c_horario !== "" && (
                      <S.TextItemSchedule6>
                        <span>Horário:</span> {data.c_horario}{" "}
                      </S.TextItemSchedule6>
                    )}

                    <S.TextItemSchedule6>{data.c_obs} </S.TextItemSchedule6>
                  </S.CenterItemSchedule>
                  {data.c_link !== "" ? (
                    <S.LeftItemSchedule
                      className="LeftItemSchedule2"
                      onClick={() => {
                        window.open(data.c_link);
                      }}>
                      <S.ButtonSchedule>
                        <S.TextSchedule7>Comprar</S.TextSchedule7>
                        <S.OutImgIconSchedule2>
                          <img src={IconSchedule4} />
                        </S.OutImgIconSchedule2>
                      </S.ButtonSchedule>
                    </S.LeftItemSchedule>
                  ) : (
                    <S.LeftItemSchedule className="LeftItemSchedule2"></S.LeftItemSchedule>
                  )}
                </S.ItemSchedule>
              </S.OutItemsSchedule>
            ))}
        </S.ContentSchedule>
      </S.OutSchedule>
    </div>
  );
};

export default Banner;
